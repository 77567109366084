import React, { useEffect, useState } from "react"

import { SiteContext, ContextProviderComponent } from "../context/mainContext"
import { graphql, Link } from "gatsby"
import QuantityPicker from "../components/QuantityPicker"
import { slugify, numberFormat } from "../utils/helpers"
import { GiWineBottle } from "react-icons/gi"
import { IoArrowForward, IoLockClosed, IoTrashBin } from "react-icons/io5"
import GImage from "../components/GImage"

const Cart = ({ context, data }) => {
  const { directus } = data
  const [deliveryType, setDeliveryType] = useState(null)
  const [deliveryCost, setDeliveryCost] = useState(null)
  const {
    numberOfItemsInCart,
    cart,
    removeFromCart,
    total,
    setItemQuantity,
    getCartItem,
  } = context
  const cartEmpty = numberOfItemsInCart === Number(0)

  function increment(item) {
    item.quantity = item.quantity + 1
    setItemQuantity(item)
  }

  function decrement(item) {
    if (item.quantity === 1) return
    item.quantity = item.quantity - 1
    setItemQuantity(item)
  }

  function changeQuantity(e, item) {
    const targetVal = parseInt(e?.target?.value)
    // console.log(targetVal)
    if (targetVal && typeof targetVal === "number" && targetVal >= 0) {
      if (targetVal <= 0 && getCartItem(item) >= 1) {
        removeFromCart(item)
        item.quantity = 1
      } else {
        item.quantity = targetVal
        if (getCartItem(item) === 0) return
        setItemQuantity(item)
      }
    }
  }

  useEffect(() => {
    if (deliveryType) {
      directus.deliveries
        .filter(function (delivery) {
          return delivery.name === deliveryType
        })[0]
        .costs.sort((a, b) => a.total - b.total)
        .forEach((cost) => {
          if (total > cost.total) {
            // console.log("cost.tarif", cost.tarif)
            setDeliveryCost(cost.tarif)
          }
        })
    }
  }, [directus.deliveries, total, deliveryType])

  const handleDeliveryChange = (e) => {
    const target = e.target
    const value = target.value
    setDeliveryType(value)
  }

  return (
    JSON.parse(process.env.GATSBY_ECOMMERCE) === true && (
      <>
        <div className="h-20" />
        <div className="flex flex-col items-center px-6 pb-10">
          <div className="flex flex-col w-full c_large:w-c_large">
            <div className="pt-6 pb-2">
              <h1 className="text-5xl font-light">Votre panier</h1>
            </div>
            {cartEmpty ? (
              <h3 className="flex items-end">
                <GiWineBottle size={50} /> Aucun vin ajouté...
              </h3>
            ) : (
              <table className="w-full flex flex-row flex-nowrap lg:bg-white rounded-lg overflow-hidden lg:shadow-lg">
                <tbody className="flex-1 lg:flex-none">
                  {cart.map((item) => {
                    return (
                      <tr
                        className="relative flex flex-col flex-nowrap shadow-lg lg:shadow-none lg:table-row mb-8 lg:mb-0"
                        key={item.id}
                      >
                        <td className="border-grey-light border hover:bg-gray-100 py-0">
                          <Link
                            className="text-left w-full flex justify-start items-center"
                            to={`/${slugify("Les Vins")}/${slugify(item.name)}`}
                          >
                            <GImage
                              className="h-[86px] w-24 m-0"
                              src={item.illustration.imageFile}
                              alt={item.name}
                            />
                            <p className="m-0 pl-10 text-gray-600 text-lg max-w-96">
                              {item.name}
                            </p>
                          </Link>
                        </td>
                        {/* <td className="border-grey-light border hover:bg-gray-100 p-3">
                        <Link
                          className="text-left"
                          to={`/${slugify("Les Vins")}/${slugify(item.name)}`}
                        ></Link>
                      </td> */}
                        <td className="border-grey-light border hover:bg-gray-100 p-3">
                          <div className="w-full flex justify-center items-center">
                            <QuantityPicker
                              hideQuantityLabel
                              numberOfitems={item.quantity}
                              increment={() => increment(item)}
                              decrement={() => decrement(item)}
                              showButtons={true}
                              changeQuantity={(e) => changeQuantity(e, item)}
                            />
                            <button
                              onClick={() => removeFromCart(item)}
                              className="m-0 ml-8 sm:ml-12 text-stone-700 cursor-pointer"
                            >
                              <IoTrashBin
                                size={22}
                                className="text-stone-500"
                              />
                            </button>
                          </div>
                        </td>
                        <td className="border-grey-light border hover:bg-gray-100 p-3">
                          <div className="flex flex-1 justify-center">
                            <p className="m-0 px-6 text-stone-700 tracking-tighter font-semibold text-xl">
                              {numberFormat(item.price_fr)}
                            </p>
                          </div>
                        </td>
                      </tr>
                    )
                  })}

                  {/* TOTAL TTC */}
                  <tr className="relative flex flex-col flex-nowrap shadow-lg lg:shadow-none lg:table-row mb-8 lg:mb-0">
                    <td
                      colSpan={2}
                      className="border-grey-light border hover:bg-gray-100 py-6"
                    >
                      <div className="text-left w-full flex justify-center lg:justify-end items-center">
                        <p className="m-0 px-6 text-stone-700 tracking-tighter font-semibold text-xl text-center lg:text-right">
                          Sous-total
                          <span className="block text-xs font-normal font-sans">
                            Taxes incluses.
                          </span>
                        </p>
                      </div>
                    </td>
                    <td className="border-grey-light border hover:bg-gray-100 p-3 py-6">
                      <div className="flex flex-1 justify-center">
                        <p className="m-0 px-6 text-stone-700 tracking-tighter font-semibold text-xl">
                          {numberFormat(total)}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            <div className="my-6 grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="">
                <h3 className="mb-3">Estimez vos frais de livraison</h3>
                <select
                  className="w-full bg-transparent border-t-0 border-l-0 border-r-0 text-lg"
                  id="delivery_estimation"
                  name="delivery_estimation"
                  onBlur={handleDeliveryChange}
                  onChange={handleDeliveryChange}
                  // value={delivery}
                  // onLoad={() =>
                  //   handleDeliveryChange({
                  //     e: {
                  //       type: "select",
                  //       value: "",
                  //       name: "delivery_estimation",
                  //     },
                  //   })
                  // }
                  // defaultValue="livraison"
                >
                  <option disabled aria-label="Choisir une option" value="">
                    Choisir une option
                  </option>
                  {directus.deliveries.map((item) => {
                    return (
                      <option
                        key={item.name}
                        aria-label={item.label}
                        value={item.name}
                      >
                        {item.label}
                      </option>
                    )
                  })}
                </select>

                {deliveryCost !== null && typeof deliveryCost === "number" && (
                  <p className="text-xl text-stone-800 mt-4">
                    <strong>Frais de port :</strong>{" "}
                    <span className="bg-gray-100 p-2 mx-1">
                      {deliveryCost > 0
                        ? numberFormat(deliveryCost)
                        : "Gratuit"}
                    </span>
                  </p>
                )}
              </div>
              <div>
                {!cartEmpty && (
                  <div className="w-full flex flex-col gap-4">
                    <div className="flex justify-end">
                      <Link
                        className="inline-flex w-auto justify-center items-center shadow-md shadow-slate-100 hover:shadow-slate-300 font-bold text-center px-6 py-3 text-lg text-white bg-emerald-400 hover:bg-emerald-600 active:bg-emerald-600 transition ease-in-out duration-150 disabled:opacity-40 disabled:cursor-not-allowed rounded-sm"
                        to="/checkout"
                      >
                        <span className="whitespace-nowrap">
                          Passer la commande
                        </span>
                        <IoArrowForward className="text-white ml-1" />
                      </Link>
                    </div>
                    <div className="flex justify-end">
                      <p className="flex items-center justify-center text-lg font-semibold text-stone-600">
                        <IoLockClosed className="text-amber-500" size={17} />
                        <span className="pl-1.5">Paiement 100% sécurisé</span>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  )
}

function CartWithContext(props) {
  return (
    <ContextProviderComponent>
      <SiteContext.Consumer>
        {(context) => <Cart {...props} context={context} />}
      </SiteContext.Consumer>
    </ContextProviderComponent>
  )
}

export default CartWithContext

export const cartPageQuery = graphql`
  query {
    directus: allDirectus {
      deliveries {
        name
        label
        costs
      }
      pages_settings {
        mentions
      }
    }
  }
`
