import React from "react"
import { FaMinus, FaPlus } from "react-icons/fa"

export default function QuantityPicker({
  increment,
  decrement,
  changeQuantity,
  numberOfitems,
  hideQuantityLabel,
  showButtons,
}) {
  return (
    <div className="flex items-center h-[58.5px]">
      {!hideQuantityLabel && <div className="px-2">Quantité</div>}
      {showButtons && (
        <button
          className="group h-full px-2 py-1 cursor-pointer focus:outline-none bg-white hover:bg-stone-600 border"
          onClick={decrement}
        >
          <FaMinus
            className="text-stone-600 group-hover:text-white"
            size={10}
          />
        </button>
      )}
      {/* <p className="w-10 h-full m-0 flex items-center justify-center font-sans tracking-wide text-base border-t border-b text-center text-stone-600 bg-white">
        <span>{numberOfitems}</span>
      </p> */}
      <input
        type="text"
        className="w-full h-full m-0 flex items-center justify-center font-sans tracking-wide text-base border border-stone-200 focus:border-stone-300 text-center text-stone-600 bg-white outline-none"
        value={numberOfitems}
        onChange={changeQuantity}
      />
      {showButtons && (
        <button
          className="group h-full px-2 py-1 cursor-pointer focus:outline-none bg-white hover:bg-stone-600 border"
          onClick={increment}
        >
          <FaPlus className="text-stone-600 group-hover:text-white" size={10} />
        </button>
      )}
    </div>
  )
}
